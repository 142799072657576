import React from 'react';
import PropTypes from 'prop-types';
import { RoundedButton, blue7, lumosWhite } from '@lumoslabs/react_lux';
import { StyleSheet, css } from 'aphrodite';
import { Container } from 'reactstrap';

const styles = StyleSheet.create({
  main: {
    overflow: 'auto',
    textAlign: 'center',
    backgroundColor: blue7,
    color: lumosWhite,
    minHeight: 280
  },
  inner: {
    transform: 'translateY(50%)',
    '@media (max-width: 767px)': {
      transform: 'translateY(65%)'
    }
  },
  heading: {
    fontSize: '2em',
    lineHeight: '1.37em',
    marginBottom: 30,
    '@media (max-width: 767px)': {
      fontSize: '1.5em'
    }
  }
});

function JobsFooter({ heading, ctaText, locale }) {
  return (
    <section className={css(styles.main)}>
      <div className={css(styles.inner)}>
        <Container>
          <h2 className={css(styles.heading)}>{heading}</h2>
          <RoundedButton href={`/${locale}/jobs`} value={ctaText} disabled={false} />
        </Container>
      </div>
    </section>
  );
}

JobsFooter.propTypes = {
  heading: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  locale: PropTypes.string
};

JobsFooter.defaultProps = {
  locale: 'en'
};

export default JobsFooter;
