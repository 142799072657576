import React from 'react';
import get from 'lodash/get';
import { Container } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import { graphql } from 'gatsby';
import { lumosWhite, fonts } from '@lumoslabs/react_lux';

import Hero from 'components/ui/Hero';
import SubNav from '../../components/subNav';
import Layout from '../../components/layout';
import Metatags from '../../components/Metatags';
// Not currently showing Team Members
// import TeamMembers from '../../components/about/teamMembers';
import JobsFooter from '../../components/about/jobsFooter';
import i18n from '../../utils/i18n';

import { mediaQuery } from '../../styles/helpers/layout';

const styles = StyleSheet.create({
  heroHeader: {
    color: lumosWhite,
    [mediaQuery.maxWidth.tablet]: {
      fontSize: '1.6em'
    }
  },
  intro: {
    paddingTop: '40',
    marginTop: 0
  },
  summary: {
    ...fonts.p
  },
  ourTeamSummary: {
    ...fonts.p,
    textAlign: 'center',
    [mediaQuery.minWidth.tablet]: {
      textAlign: 'left'
    }
  },
  header: {
    ...fonts.h3,
    marginTop: 40,
    marginBottom: 20,
    textAlign: 'center',
    [mediaQuery.minWidth.tablet]: {
      textAlign: 'left'
    }
  },
  teamMembers: {
    marginBottom: 40
  }
});

class AboutIndexPage extends React.Component {
  render() {
    const {
      pageContext: { locale }
    } = this.props;

    // Normally we'd want to guard against empty before addressing into the array,
    // and accessing the first element.  But in this case, if the AboutUs
    // content somehow vanishes, we really do want the build to break, no graceful
    // fallback, so it's more like a deliberately brittle circuit breaker.
    const page = get(this.props, 'data.allContentfulAbout.edges')[0].node

    return (
      <div>
        <Layout locale={locale} location={this.props.location}>
          <Metatags
            title={i18n.t('AboutUsPage.title')}
            description={i18n.t('AboutUsPage.description')}
            type="article"
            canonical={`${locale}/about/`}
            locale={locale}
          />
          <SubNav locale={locale} />
          <Hero fluidImage={page.heroImage.fluid} imageAlt={page.title}>
            <h2 className={css(styles.heroHeader)}>{page.header}</h2>
          </Hero>
          <Container>
            <div
              className={css(styles.intro, styles.summary)}
              dangerouslySetInnerHTML={{
                __html: page.intro.childMarkdownRemark.html,
              }}
            />
          </Container>
          <JobsFooter heading={page.footer} ctaText={page.ctaText} locale={locale} />
        </Layout>
      </div>
    )
  }
};

export default AboutIndexPage;

export const pageQuery = graphql`
  query AboutUsQuery($locale: String!) {
    allContentfulAbout(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          headerTitle
          title
          heroImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          header
          intro {
            childMarkdownRemark {
              html
            }
          }
          paragraphHeader
          paragraph {
            childMarkdownRemark {
              html
            }
          }
          people {
            name
            shortBio {
              childMarkdownRemark {
                html
              }
            }
            title
            image {
              fluid(maxWidth: 260, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          footer
          ctaText
        }
      }
    }
  }
`;
